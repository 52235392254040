<template>
  <div>
    <!-- for place of iframe  -->
    <div class="rwlivechat-area" v-if="configLivechat.chatOpen">
      <div
        class="rwlivechat-close rw-times"
        @click="$store.dispatch('Livechat', { chatOpen: false })"
      ></div>
      <iframe id="rwlivechat-iframe" :src="iframeLink"></iframe>
    </div>

    <!--for button-->
    <div class="livesupport-btn hidden-sm hidden-xs visible-lg visible-md">
      <div class="livechatbanner" v-if="configLivechat.btnBanner">
        <a
          href="javascript:void(0)"
          class="livesupportclose"
          title="Tutup LiveChat"
          @click="$store.dispatch('Livechat', { btnBanner: false })"
        >
          <i class="fa fa-times-circle"></i>
        </a>
        <p class="bubblespeech">
          <a
            href="javascript:void(0)"
            title="Start LiveChat Now"
            class="btn livechat"
            @click="closeBanner"
            >&nbsp;</a
          >
        </p>
      </div>
      <div class="livechat-btn" v-else>
        <a
          href="javascript:void(0)"
          class="livechat"
          @click="
            $store.dispatch('Livechat', { chatOpen: !configLivechat.chatOpen })
          "
        >
          <span><i class="fa fa-comment-alt" style="font-size: 25px"></i></span>
          Chat With Us
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { LIVECHAT } from "@/store/rw/util.module";
import "@/assets/rw/css/rw_livechat.css";
import { mapGetters } from "vuex";

export default {
  name: "widgetLiveChat",
  data() {
    return {
      icon: "assets/rw/livechat-btn.png",
      iframeLink: "http://localhost:8081"
    };
  },
  methods: {
    closeBanner: function() {
      this.$store.dispatch(LIVECHAT, { btnBanner: false, chatOpen: true });
    }
  },
  computed: {
    ...mapGetters(["configLivechat"])
  }
};
</script>

<style scoped></style>
