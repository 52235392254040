<template>
  <div class="kt-menu__link" @click="trigger_click">
    <i v-if="menu.icon" class="kt-menu__link-icon" :class="menu.icon"></i>
    <span class="kt-menu__link-text">{{ menu.title }}</span>
  </div>
</template>

<script>
import { LIVECHAT } from "@/store/rw/util.module";
import { mapGetters } from "vuex";

export default {
  name: "MenuItemButton",
  props: {
    menu: Object
  },
  methods: {
    /**
     * --------------------------------------------------
     * check type button digunakan untuk apa
     * silahkan tambah if jika ada type baru
     * --------------------------------------------------
     */
    trigger_click: function() {
      let type = this.menu.buttonOpt;
      if (type === "livechat") {
        this.open_chat();
      } else {
        console.error(
          `MenuItemButton.vue : menu ${this.menu.title} tidak memiliki type`
        );
      }
    },

    /**
     * --------------------------------------------------
     * open livechat support
     * --------------------------------------------------
     */
    open_chat: function() {
      this.$store.dispatch(LIVECHAT, {
        btnBanner: false,
        chatOpen: !this.configLivechat.chatOpen
      });
    }
  },
  computed: {
    ...mapGetters(["configLivechat"])
  }
};
</script>

<style scoped></style>
