<template>
  <router-link
    :to="link()"
    :class="{ 'kt-menu__toggle': menu.submenu }"
    class="kt-menu__link"
  >
    <i v-if="menu.icon" class="kt-menu__link-icon" :class="menu.icon"></i>
    <i
      v-if="!menu.icon && !menu.root"
      class="kt-menu__link-bullet"
      :class="bullet()"
    ></i>
    <span class="kt-menu__link-text">{{
      menu.translate ? $t(menu.translate) : menu.title
    }}</span>
    <span v-if="menu.jumlah" class="badge badge-primary">
      <i class="fa fa-spin fa-spinner" v-if="loading.sum"></i>
      <span v-else>{{ get_count(menu.title) }}</span>
    </span>
    <i v-if="menu.submenu" class="kt-menu__ver-arrow la la-angle-right"></i>
  </router-link>
</template>

<script>
export default {
  name: "rw-MenuItemText",
  props: {
    menu: Object,
    parentMenu: Object,
    sumProduct: Object
  },
  data() {
    return {
      loading: {
        sum: true
      }
    };
  },
  methods: {
    /**
     * --------------------------------------
     * Get bullet class from config
     * @returns {string} class name
     * --------------------------------------
     */
    bullet: function() {
      // get the default bullet
      if (!this.menu.bullet) {
        this.menu.bullet = "dot";
      }
      // bullet class name
      return `kt-menu__link-bullet--${this.menu.bullet}`;
    },

    /**
     * --------------------------------------
     * Get current menu link path
     * @returns {{}|{path: string}}
     * --------------------------------------
     */
    link: function() {
      if (!this.menu.page) {
        // check if no link in this menu item
        return {};
      }
      // pass the link prepend with slash
      return { path: `/${this.menu.page}` };
    },

    /**
     * ----------------------------------------
     * counter sidebar
     * ----------------------------------------
     */
    get_count(nameCaps) {
      let name = nameCaps.trim();
      name = name.toLowerCase();
      return this.sumProduct[name];
    }
  },
  mounted() {
    let self = this;

    // dummy loading
    setTimeout(() => {
      self.loading = false;
    }, 2000);
  }
};
</script>
