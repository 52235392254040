<template>
  <b-link
    @click="link()"
    :class="{ 'kt-menu__toggle': menu.submenu }"
    class="kt-menu__link"
    target="_blank"
  >
    <i v-if="menu.icon" class="kt-menu__link-icon" :class="menu.icon"></i>
    <i
      v-if="!menu.icon && !menu.root"
      class="kt-menu__link-bullet"
      :class="bullet()"
    ></i>
    <span class="kt-menu__link-text">{{
      menu.translate ? $t(menu.translate) : menu.title
    }}</span>
    <span v-if="menu.jumlah" class="badge badge-primary">
      <i class="fa fa-spin fa-spinner" v-if="loading.sum"></i>
      <span v-else>{{ get_count(menu.title) }}</span>
    </span>
    <i v-if="menu.submenu" class="kt-menu__ver-arrow la la-angle-right"></i>
  </b-link>
</template>

<script>
import { ApiURL, util, loading } from "@/common/mixins/general.js";
export default {
  name: "rw-MenuItemTextExternal",
  mixins: [ApiURL, util, loading],
  props: {
    menu: Object,
    parentMenu: Object,
    sumProduct: Object
  },
  data() {
    return {
      loading: {
        sum: true
      }
    };
  },
  methods: {
    /**
     * --------------------------------------
     * Get bullet class from config
     * @returns {string} class name
     * --------------------------------------
     */
    bullet: function() {
      // get the default bullet
      if (!this.menu.bullet) {
        this.menu.bullet = "dot";
      }
      // bullet class name
      return `kt-menu__link-bullet--${this.menu.bullet}`;
    },

    /**
     * --------------------------------------
     * Get current menu link path
     * @returns {{}|{path: string}}
     * --------------------------------------
     */
    link: function() {
      this.mx_loading();
      if (!this.menu.pageCustom) {
        this.mx_redirect("https://rumahweb.co.id", false);
      } else {
        // custom redirect external link
        this.get_external_link(
          this.menu.pageCustom.option1,
          this.menu.pageCustom.option2
        );
      }
    },

    /**
     * ----------------------------------------
     * counter sidebar
     * ----------------------------------------
     */
    get_count(nameCaps) {
      let name = nameCaps.trim();
      name = name.toLowerCase();
      return this.sumProduct[name];
    },

    /**
     * ----------------------------------------
     * get external link
     * ----------------------------------------
     */
    get_external_link(option1, option2) {
      this.xhr_tools
        .get(this.api.tools.redirect[option1], option2)
        .then(({ data }) => {
          this.mx_redirect(data.data.url, false);
        })
        .finally(() => this.mx_loading(false));
    }
  },
  mounted() {
    let self = this;

    // dummy loading
    setTimeout(() => {
      self.loading = false;
    }, 2000);
  }
};
</script>
