<template>
  <!-- begin:: Header Topbar -->
  <div class="kt-header__topbar">
    <!--begin: User Bar -->
    <div class="kt-header__topbar-item kt-header__topbar-item--user">
      <div
        class="kt-header__topbar-wrapper"
        id="kt_user_toggle"
        data-toggle="dropdown"
      >
        <div class="kt-header__topbar-user">
          <span class="kt-header__topbar-welcome">Hi,</span>
          <span class="kt-header__topbar-username">
            <span v-if="user.firstname" v-html="user.firstname"></span>
            <span v-if="!user.firstname">
              <b-skeleton width="70px"></b-skeleton>
            </span>
          </span>
          <span
            class="
              kt-badge
              kt-badge--username
              kt-badge--unified-success
              kt-badge--lg
              kt-badge--rounded
              kt-badge--bold
            "
            >{{ avatar }}</span
          >
        </div>
      </div>
      <div
        class="
          dropdown-menu dropdown-menu-fit dropdown-menu-xl dropdown-menu-right
        "
      >
        <RWDropdownUser :profile="user"></RWDropdownUser>
      </div>
    </div>
    <!--end: User Bar -->
  </div>
  <!-- end:: Header Topbar -->
</template>

<script>
// import KTSearchDefault from "@/views/theme/topbar/SearchDefault.vue";
// import KTDropdownNotification from "@/views/theme/topbar/DropdownNotification.vue";
// import KTDropdownQuickAction from "@/views/theme/topbar/DropdownQuickAction.vue";
// import KTDropdownMyCart from "@/views/theme/topbar/DropdownMyCart.vue";
// import KTDropdownLanguage from "@/views/theme/topbar/DropdownLanguage.vue";
import RWDropdownUser from "@/views/theme/topbar/DropdownUser.vue";
import i18nService from "@/common/i18n.service.js";
import { mapGetters } from "vuex";

export default {
  name: "RWTopbar",
  data() {
    return {
      languageFlag: "",
      languages: i18nService.languages
    };
  },
  components: {
    RWDropdownUser
  },
  methods: {
    onLanguageChanged() {
      this.languageFlag = this.languages.find(val => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    }
  },
  computed: {
    ...mapGetters(["user"]),
    getLanguageFlag() {
      return this.onLanguageChanged();
    },

    /**
     * --------------------------------------
     * use below badge element instead the user avatar to display username's first letter
     * --------------------------------------
     */
    avatar() {
      if (typeof this.user.firstname !== "undefined") {
        let str = this.user.firstname;
        str = str.substring(0, 1);
        return str.toUpperCase();
      }
      return "R";
    }
  }
};
</script>
