<template>
  <li
    class="kt-menu__item"
    aria-haspopup="true"
    :class="{
      'kt-menu__item--submenu': menu.submenu,
      'kt-menu__item--open': activeMenu(menu)
    }"
    :data-ktmenu-submenu-toggle="submenuToggle(menu)"
  >
    <RWMenuItemButton v-if="menu.button" :menu="menu"></RWMenuItemButton>
    <template v-else>
      <KTMenuItemText
        v-bind:menu="menu"
        :sumProduct="sumProduct"
        v-if="menu.page"
      ></KTMenuItemText>
      <RWMenuItemTextExternal
        v-bind:menu="menu"
        :sumProduct="sumProduct"
        v-else
      ></RWMenuItemTextExternal>
      <KTMenuSubmenu
        v-if="menu.submenu"
        v-bind:submenu="menu.submenu"
        v-bind:parentMenu="menu"
      ></KTMenuSubmenu>
    </template>
  </li>
</template>

<script>
import KTMenuItemText from "@/views/theme/aside/MenuItemText.vue";
import RWMenuItemTextExternal from "@/views/theme/aside/MenuItemTextExternal.vue";
import KTMenuSubmenu from "@/views/theme/aside/MenuSubmenu.vue";
import RWMenuItemButton from "@/views/theme/aside/MenuItemButton";

export default {
  name: "rw-MenuItem",
  components: {
    KTMenuItemText,
    KTMenuSubmenu,
    RWMenuItemTextExternal,
    RWMenuItemButton
  },
  props: {
    menu: Object,
    sumProduct: Object
  },
  methods: {
    activeMenu(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some(conf => {
        if (conf.submenu) {
          return this.activeMenu(conf.submenu);
        }
        if (conf.page) {
          // current path starts with this path string
          return this.$route.path === `/${conf.page}`;
        }
      });
    },
    /**
     * Get submenu toggle type
     * @param menu
     * @returns {string|*}
     */
    submenuToggle(menu) {
      if (menu.hasOwnProperty("toggle")) {
        return menu.toggle;
      }
      return "hover";
    }
  }
};
</script>
