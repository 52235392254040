<template>
  <ul class="kt-menu__nav">
    <li v-for="(br, i) in breadcrumbs" :key="i" class="kt-menu__item">
      <!-- <a v-bind:href="getURL(br)" class="kt-menu__link">
          <span class="kt-menu__link-text">{{br.title}}</span>
        </a> -->
      <router-link
        :to="getURL(br)"
        class="kt-menu__link"
        :style="getBackground(i, last)"
      >
        <span class="kt-menu__link-text">{{ br.title }}</span>
      </router-link>
      <!-- <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-if="i != last" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M12.293 6.707a1 1 0 111.414-1.414l6 6a1 1 0 01.03 1.383l-5.5 6a1 1 0 01-1.474-1.352l4.853-5.294-5.323-5.323z" fill="#000" fill-rule="nonzero"/><path d="M5.293 6.707a1 1 0 111.414-1.414l6 6a1 1 0 01.03 1.383l-5.5 6a1 1 0 11-1.474-1.352l4.853-5.294-5.323-5.323z" fill="#000" fill-rule="nonzero" opacity=".3"/></g></svg>  -->
      <i v-if="i != last" class="fa fa-angle-right"></i>

      <!-- src="@/assets/media/icons/svg/Navigation/Angle-right.svg" -->
    </li>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
// import KTMenuItem from "@/views/theme/header/MenuItem.vue";
import menuConfig from "@/common/config/menu.config.json";

export default {
  name: "RWHeaderMenu",
  components: {
    // KTMenuItem
    // RWBreadcrumb
  },
  methods: {
    getURL(breadcrumb) {
      return typeof breadcrumb.route != "undefined"
        ? "/" + breadcrumb.route
        : "/";
    },
    getBackground(i, last) {
      if (i == last) {
        return {
          background: "#69f0ae"
        };
      }
    }
  },
  computed: {
    menuItems: () => {
      return menuConfig.header.items;
    },
    ...mapGetters(["breadcrumbs"]),
    last() {
      return Object.keys(this.breadcrumbs).pop();
    }
  }
};
</script>

<style scoped>
.kt-menu__link-text {
  color: #181c32 !important;
}
.kt-menu__item .kt-menu__link-text:hover {
  color: black !important;
}
</style>
