<template>
  <div>
    <div
      class="
        kt-user-card kt-user-card--skin-dark
        kt-notification-item-padding-x
      "
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <div
        class="kt-user-card__name"
        v-html="profile.firstname + ' ' + profile.lastname"
      ></div>
      <div class="kt-user-card__badge">
        <!-- <a href="#" v-on:click="onLogout()" class="btn btn-danger">Sign Out</a> -->
      </div>
    </div>
    <div class="kt-notification">
      <b-link :to="{ path: '/profile' }" class="kt-notification__item">
        <div class="kt-notification__item-icon">
          <i class="flaticon2-calendar-3 kt-font-success"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">My Profile</div>
          <div class="kt-notification__item-time">
            Account settings and more
          </div>
        </div>
      </b-link>

      <b-link :to="{ path: '/invoice' }" class="kt-notification__item">
        <div class="kt-notification__item-icon">
          <i class="flaticon2-cardiogram kt-font-warning"></i>
        </div>
        <div class="kt-notification__item-details">
          <div class="kt-notification__item-title kt-font-bold">Billing</div>
          <div class="kt-notification__item-time">Billing & Statements</div>
        </div>
      </b-link>

      <div class="kt-notification__custom kt-space-between">
        <a>&nbsp;</a>
        <a
          href="#"
          v-on:click="onLogout()"
          class="btn btn-clean btn-sm btn-bold"
          >Sign Out</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { LOGOUT } from "@/store/auth.module";

export default {
  name: "RWDropdownUser",
  props: {
    profile: {
      type: Object,
      required: true
    }
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    }
  },
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/misc/bg-1.jpg";
    }
  }
};
</script>
